import * as THREE from "three";
import React, { useMemo, useRef, useLayoutEffect } from "react";
import { extend, useLoader } from "@react-three/fiber";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import boldUrl from "./bold.blob";

const material = new THREE.MeshPhysicalMaterial({
  color: new THREE.Color("#DB20B2").convertSRGBToLinear(),
  roughness: 0,
  clearcoat: 1,
  clearcoatRoughness: 0,
});

export default function Text({
  children,
  vAlign = "center",
  hAlign = "center",
  size = 1.5,
  color = "#000000",
  ...props
}) {
  extend({ TextGeometry });
  const font = useLoader(FontLoader, boldUrl);
  const config = useMemo(
    () => ({
      font,
      size: 20,
      height: 30,
      curveSegments: 32,
      bevelEnabled: true,
      bevelThickness: 3,
      bevelSize: 2,
      bevelOffset: 0,
      bevelSegments: 8,
    }),
    [font]
  );
  const mesh = useRef();
  useLayoutEffect(() => {
    const size = new THREE.Vector3();
    mesh.current.geometry.computeBoundingBox();
    mesh.current.geometry.boundingBox.getSize(size);
    /*mesh.current.position.x =
      hAlign === "center" ? -size.x / 2 : hAlign === "right" ? 0 : -size.x;
    mesh.current.position.y =
      vAlign === "center" ? -size.y / 2 : vAlign === "top" ? 0 : -size.y;*/
  }, [children]);

  /*useFrame(({ clock }) => {
    mesh.current.rotation.y = clock.getElapsedTime();
  });*/
  return (
    <group {...props} scale={0.1} rotation={[0, Math.PI / 2 + 1.2, 0]}>
      <mesh ref={mesh} material={material} receiveShadow castShadow>
        <textGeometry args={[children, config]} />
      </mesh>
    </group>
  );
}
